import { User } from "firebase/auth";

export type UserAuth = {
    uid: string,
    displayName: string | null,
    email: string | null,
    role: string,
}
export type Session = {
    user?: UserAuth,
    token?: string,
    isAuthenticated?: boolean,
    redirectPath: string,
}
  
export const initialSession: Session = {
    redirectPath: '/home'
};