import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
  } from "react-geocode";

setKey("AIzaSyBvSs172REoJDfGBI9XEWehmXlydhdcKEY");
setLanguage("es");
setRegion("es");


type GeoCoordinates = {
    lat: string,
    lng: string
}

const useGeocoder  = () => {
    const getAddress = async ({lat, lng} : GeoCoordinates) => {
        const latlng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
        };
        return new Promise((resolve, reject) => {
            geocode(RequestType.LATLNG, `${latlng.lat},${latlng.lng}`)
              .then(({ results }) => {
                //console.warn("google results:",results);
                //const address = results[0].formatted_address;
                const address = results[5].formatted_address;
                //console.log(address);
                resolve(address);
              })
              .catch(e => {
                console.log(e)
                reject('No disponible');
              });
        })
        
        
    }
    return {
        getAddress,
    }
}

export default useGeocoder