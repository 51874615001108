import { ListGroup, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const PanelAside = () => {
    return (
        <>
            <div className="mt-5">
                <p className="panel-aside-title">PANEL</p>
                <ListGroup variant="flush">
                    <Link to='/panel/home' style={{textDecoration: 'none', border: 'none'}}><ListGroup.Item><i className="menu-icon bi bi-house"></i> Dashboard</ListGroup.Item></Link>
                    <Link to='/panel/users' style={{textDecoration: 'none', border: 'none'}}><ListGroup.Item><i className="menu-icon bi bi-people"></i> Usuarios</ListGroup.Item></Link>
                </ListGroup>
            </div>
        </>
    )
}
export default PanelAside;