import { useContext, useState } from 'react';
import { Col, Container, Nav, Row, Tab, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IArea } from "../../@types/project";
import { ProjectContext } from '../../context/projectContext';
import AreaFinanceTable from '../AreaFinanceTable/AreaFinanceTable';
import AreaGallery from '../AreaGallery/AreaGallery';
import InterestedCard from '../InterestedCard/InterestedCard';
import PanoViewer from '../PanoViewer/PanoViewer';
import './FloorCard.scss';

type Props = {
    area?: IArea,
    handleClose: any
}

const FloorCard = ({area, handleClose} : Props) => {

    const context = useContext(ProjectContext);
    const [show, setShow] = useState(true);
    const [showInterested, setShowInterested] = useState(false);
    const [showFinanceInfo, setShowFinanceInfo] = useState(false);
    const [activeTab, setActiveTab] = useState<any>("gallery");
    const level = context?.project.buildings[0].levels.find((l : any) => l.level === context?.simulator.current_level)

    const mainImage = `projects/portiko/prototypes/prototype-${area?.prototype_code}.jpg`;
    const gallery = context?.project.assets.galleries.find((g:any) => g.prototypes.includes(area?.prototype_code));

    const panoramaImages = context?.project.assets.views;
    const panoramaImageOrientation = panoramaImages.find((o: any) => o.prototypes.includes(area?.prototype_code) );
    const panoramaImage = panoramaImageOrientation ? `projects/portiko/views/${panoramaImageOrientation.name}/${panoramaImageOrientation.name}-${context?.simulator.current_level}.jpg` : '';

    const onHandleClose = () => {
        handleClose();
    };

    return (
        <>
        <Modal
            className='area-card'
            show={show}
            fullscreen={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHandleClose}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                <span className="home--logo" style={{ backgroundImage: `url(${ context?.project.styles.intro.logo })` }}></span>
            </Modal.Title>
            <Button className="btn-close btn-close-red" onClick={() => onHandleClose()}>Volver <span>X</span></Button>
          </Modal.Header>
          <Modal.Body className='p-0' style={{ backgroundColor: showFinanceInfo ? 'rgba(43, 28, 69, 1);': '' }}>
            <Tab.Container id="area-tabs" defaultActiveKey="gallery" onSelect={key => setActiveTab(key) }>
                <aside className='area-card--aside'>
                    <Row>
                        <Col>
                            <div>
                                <span className='d-block text-primary area--area'>Nivel { level?.level }</span>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div className='area-card--tabs-nav text-center my-4'>
                        <Nav.Item className='d-inline-block'>
                            <Nav.Link eventKey="gallery" className={`btn btn-text px-2 mx-1 my-1 text-primary ${activeTab=='gallery' ? 'active' : ''}`}>Galería</Nav.Link>
                        </Nav.Item>
                        { panoramaImage && 
                            <Nav.Item className='d-inline-block'>
                                <Nav.Link eventKey="view" className={`btn btn-text px-2 mx-1 my-1 text-primary ${activeTab=='view' ? 'active' : ''}`}>Vista</Nav.Link>
                            </Nav.Item>
                        }
                        <Nav.Item className='d-inline-block'>
                            <Nav.Link eventKey="tour" className={`btn btn-text px-2 mx-1 my-1 text-primary ${activeTab=='tour' ? 'active' : ''}`}>Tour 360</Nav.Link>
                        </Nav.Item>
                    </div>
                </aside>
                <main className={`area-card--main tab-${activeTab}`}>
                        <Tab.Content>
                            <Tab.Pane eventKey="gallery">
                                { activeTab=='gallery' && gallery &&
                                    <AreaGallery images={gallery.images} className='area-card--gallery' />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="view">
                                { activeTab=='view' &&
                                    <PanoViewer image={panoramaImage}/>
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="tour">
                                <div className="area-card--iframe">
                                    <iframe src="https://popinvestments.com/portiko/360/"></iframe>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>    
                </main>
            </Tab.Container>
          </Modal.Body>
        </Modal>
        </>
      );
}

export default FloorCard;