import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import { FormEvent, useEffect, useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { UserType } from "../../../@types/user"
import { FirebaseError } from "firebase/app"
import { doc, serverTimestamp, setDoc } from "firebase/firestore"
import useLogger from "../../../hooks/useLogger"
import { useSessionContext } from "../../../context/sessionContext"
import { LOCAL_API_BASE } from "../../../data/project"
import axios, { AxiosError } from "axios"

type Props = {
    selectedUser: UserType,
    onHandleCancel: () => void,
    onHandleClose: () => void,
}
type ResponseMessageType = {
    responseType: string,
    message: string,
}

const UsersForm = ({selectedUser, onHandleCancel, onHandleClose} : Props) => {
    const [session] = useSessionContext();
    const defaultUser = {
        uid: '',
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        role: '',
    }
    const [user, setUser] = useState<UserType>(defaultUser)
    const [responseMessage, setResponseMessage] = useState<ResponseMessageType | null>();
    const [isLoading, setIsLoading] = useState(false);
    const logger = useLogger()

    useEffect(() => {
        setUser(selectedUser)
        console.warn("setUser",selectedUser);
    },[])

    const register = async (user : UserType) => {
        setIsLoading(true);
        try {
            const currentUserToken = session.token;
            const newUser = await axios.post(LOCAL_API_BASE+`/register`,{
                name: user.name,
                email: user.email,
                password: user.password,
                password_confirmation: user.passwordConfirmation,
                role: user.role,
            },{
                headers: { 
                    Authorization: `Bearer ${currentUserToken}`,
                    "Content-Type": "multipart/form-data" 
                },                
            }).then(response => {
                const newUser = response.data;
                setUser({
                    ...user,
                    uid: newUser.user.id
                })
                setResponseMessage({responseType: 'success', message: 'Se ha creado correctamente el usuario.'});
                logger.log({ action: 'REGISTER', message: `Nuevo usuario registrado: ${newUser.user.email}` })
                onHandleClose()
            });
        }
        catch (err: any | AxiosError) {
            if (axios.isAxiosError(err))  {
                // Access to config, request, and response
                console.warn("error:",err);
                let message = err.message;
                if(err.response?.data?.errors){
                    message += ': ';
                    Object.values(err.response?.data?.errors).map((i:any) => message += `${i[0]}.` ) 
                }
                setResponseMessage({responseType: 'error', message: message});
              } else {
                // Just a stock error
              }
            setIsLoading(false);
        }

    }
    const update = async (user : UserType) => {
        setIsLoading(true);
        try {
            const currentUserToken = session.token;
            const newUser = await axios.post(LOCAL_API_BASE+`/user/${user.uid}`,{
                name: user.name,
                email: user.email,
                password: user.password,
                password_confirmation: user.passwordConfirmation,
                role: user.role,
            },{
                headers: { 
                    Authorization: `Bearer ${currentUserToken}`,
                    "Content-Type": "multipart/form-data" 
                },                
            }).then(response => {
                const newUser = response.data;
                setResponseMessage({responseType: 'success', message: 'Se ha actualizado correctamente el usuario.'});
                logger.log({ action: 'UPDATE', message: `Usuario actualizado: ${newUser.email}` })
                onHandleClose()
            });
        } catch (error) {
            const errorCode = (error as FirebaseError).code;
            console.warn("error:",error);
        }
    }

    const handleCancel = () => {
        onHandleCancel();
    }
    const handleChange = (event : any) => {
        setUser({ 
            ...user, 
            [event.target.name] : event.target.value
        });
    }
    const handleSubmit = (e : FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(user.uid){
            // update
            update(user)
        }
        else {
            // register
            register(user)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="name">
                <Form.Label><i className="bi bi-person"></i> Nombre</Form.Label>
                <Form.Control type="text" name="name" placeholder="Juan Pérez" value={user.name} onChange={handleChange} />
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label><i className="bi bi-envelope"></i> Email</Form.Label>
                        <Form.Control type="text" name="email" placeholder="micorreo@correo.com" value={user.email} onChange={handleChange} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="role">
                        <Form.Label><i className="bi bi-building"></i> Rol</Form.Label>
                        <Form.Select aria-label="Rol" name="role" onChange={handleChange} value={user.role}>
                            <option>Seleccione...</option>
                            <option value="admin">Administrador</option>
                            <option value="sales">Ventas</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            { (!user.uid.length || user.role==="admin") &&          
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label><i className="bi bi-lock"></i> Nueva Contraseña</Form.Label>
                            <Form.Control type="password" name="password" placeholder="******" value={user.password} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="passwordConfirmation">
                            <Form.Label><i className="bi bi-lock"></i> Confirmar Contraseña</Form.Label>
                            <Form.Control type="password" name="passwordConfirmation" placeholder="******" value={user.passwordConfirmation} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row>
            }
            <Form.Group className="mb-3">
                <Button variant="primary" className="text-white" type="submit" disabled={isLoading}><i className="bi bi-save"></i> Guardar</Button>
                <Button variant="default" onClick={handleCancel}><i className="bi bi-cross"></i> Cancelar</Button>
            </Form.Group>
            { responseMessage && 
                <Row>
                    <Col>
                        <p className={responseMessage.responseType === 'success' ? 'text-success' : 'text-danger'}>{responseMessage.message}</p>
                    </Col>
                </Row>
            }
            <Form.Group controlId="uid">
                <Form.Control type="hidden" value={user.uid} onChange={handleChange} className="mt-4" readOnly />
            </Form.Group>
        </Form>
    )
}

export default UsersForm