import { Chart } from "react-google-charts";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { DateRangePicker, SelectPicker } from 'rsuite';
import PanelLayout from "../../../components/AdminPanel/PanelLayout/PanelLayout";
import StatsBox from "../../../components/AdminPanel/StatsBox/StatsBox";
import LogTable from "../../../components/AdminPanel/LogTable/LogTable";
import PanelAside from "../../../components/AdminPanel/PanelAside/PanelAside";
import { useEffect, useState } from "react";
import moment from 'moment'
import 'moment/locale/es'
import 'rsuite/DateRangePicker/styles/index.css';
import 'rsuite/SelectPicker/styles/index.css';
import axios from "axios";
import { LOCAL_API_BASE } from "../../../data/project";
moment.locale('es');

type Filters = {
    user: string,
    date_range: Array<[]>,
}

const HomePanel = () => {

    const defaultUser = {
        user: '',
        date_range: [],
    }
    const [ logData, setLogData ] = useState<any[]>([])
    const [ graphData, setGraphData ] = useState<any[]>(["user","quantity"])
    const [ selectData, setSelectData ] = useState<any[]>([])
    const [ selectInitialData, setSelectInitialData ] = useState<any[]>([])
    const [ filters, setFilters ] = useState<Filters>(defaultUser);
    const [ stats, setStats ] = useState<any>()
    
    const getData = async () => {
        let q = null;
        let url = LOCAL_API_BASE+'/logger';
        if(filters.date_range.length){
            let startAt = moment(filters.date_range[0]).format('YYYY-MM-DD');
            let endAt = moment(filters.date_range[1]).format('YYYY-MM-DD');
            if(filters.user)
                url += `?user=${filters.user}&created_at_from=${startAt}&created_at_to=${endAt}&created_at=desc`; 
            else
                url += `?created_at_from=${startAt}&created_at_to=${endAt}&created_at=desc`; 
        }
        else {
            if(filters.user)
                url += `?user=${filters.user}&created_at=desc`; 
            else
                url += '?orderBy=desc'; 
        }
        //const querySnapshot = await getDocs(q);
        await axios(url).then(async response => {
            const data = response.data; 
            const result : any[] = [];
            data.forEach((doc : any) => {
                result.push(doc);
            });
            setLogData(result);
    
            const resultGraphData = Array<any>(["user","quantity"]);
            const resultSelectData = Array<any>();
            result.forEach(r => {
                const a = resultGraphData.find(x => x[0] === r.user.name );
                if(a)
                    a[1] = a[1]+1
                else{
                    resultGraphData.push([r.user.name,1])
                    resultSelectData.push({
                        label: r.user.name,
                        value: r.user.id,
                    });
                }
            })
            setGraphData(resultGraphData);
            /*setGraphData([
                ["Work", 11],
                ["Eat", 2],
                ["Commute", 2],
                ["Watch TV", 2],
                ["Sleep", 7],
            ])*/
            setSelectData(resultSelectData)
            if(!selectInitialData.length)
                setSelectInitialData(resultSelectData)
        });
    }
    useEffect(() => {
        getData();
        setFilters({
            user: '',
            date_range: [],
        })
    },[])
    useEffect(() => {
        const counters = logData.reduce((acc, cur) => {
            const date = moment(cur.created_at).format('YYYYMMDD')
            if(!acc[date])
                acc[date] = 1;
            else
                acc[date] += 1;
            return acc
        },{})
        const sum = Object.values(counters).reduce((acc:number, cur:any) => acc + cur, 0);
        let dailyAccess = 0;
        if(counters && sum)
            dailyAccess = sum/Object.values(counters).length;
        setStats({
            ...stats,
            dailyAccess,
        })
    },[logData])
    useEffect(() => {
        console.warn(filters);
        getData();
    },[filters])

    const handleChangeFilters = ({filter, value} : any) => {
        setFilters({...filters, [filter]: value || (filter=='date_range' ? [] : '')});
    }

    return (
        <PanelLayout leftbar={<PanelAside />}>
            <>
            <Container fluid="md" className="mt-5">
                <Row className="d-flex align-items-stretch">
                    <Col className="d-flex align-self-stretch pb-3 ">
                        <StatsBox title={logData[0] ? moment(logData[0]?.created_at).fromNow() : '-'} description={'Último movimiento de usuarios'} icon="bi bi-calendar-check" />
                    </Col>
                    <Col className="d-flex align-self-stretch pb-3">
                        <StatsBox title={`${graphData.length-1} ${graphData.length-1 > 1 ? 'usuarios' : 'usuario' } `} description={'activos durante este periodo'} icon="bi bi-people" />
                    </Col>
                    <Col className="d-flex align-self-stretch pb-3">
                        <StatsBox title={`${stats?.dailyAccess.toFixed(1) || 0} /día`} description={'Promedio de accesos por día'} icon="bi bi-graph-up" />
                    </Col>
                </Row>
            </Container>
            <Container fluid="md" className="mt-3">
                <Row>
                    <Col className="mb-3" md={3}>
                        <DateRangePicker onChange={(value, e) => handleChangeFilters({ filter: 'date_range', value })} />
                    </Col>
                    <Col md={3}>
                        <SelectPicker label="Usuario" data={selectInitialData} style={{ width: 224 }} placeholder='Todos' onChange={(value, e) => handleChangeFilters({ filter: 'user', value }) } />
                    </Col>
                </Row>
                { !filters.user.length &&
                    <Row>
                        <Col>
                            <Chart
                                chartType="PieChart"
                                data={graphData}
                                options={{title: 'Total de accesos'}}
                                width={"100%"}
                                height={"400px"}
                                />
                        </Col>
                    </Row>
                }
            </Container>
            <Container fluid="md" className="mt-3">
                <Row>
                    <Col>
                        <LogTable data={logData} />
                    </Col>
                </Row>
            </Container>
            </>
        </PanelLayout>
    )

}

export default HomePanel;