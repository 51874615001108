import { Badge, Button, Card, Col, Image, Row, Stack, Table } from "react-bootstrap"
import moment from 'moment'
import 'moment/locale/es'
import UsersForm from "../UsersForm/UsersForm";
import { useState } from "react";
import { UserType } from "../../../@types/user";
import axios, { AxiosError } from "axios";
import { LOCAL_API_BASE } from "../../../data/project";
import { useSessionContext } from "../../../context/sessionContext";
import useLogger from "../../../hooks/useLogger";
moment.locale('es');


type Props = {
    data: any[],
    onHandleRefresh: () => void
}

type ResponseMessageType = {
    responseType: string,
    message: string,
}

const UsersTable = ({data, onHandleRefresh} : Props) => {

    const [session] = useSessionContext();
    const currentUser = session.user;
    const [responseMessage, setResponseMessage] = useState<ResponseMessageType | null>();
    const logger = useLogger()
    const [ selectedUser, setSelectedUser ] = useState<UserType | null>()
    const defaultUser = {
        uid: '',
        name: '',
        email: '',
        username: '',
        password: '',
        passwordConfirmation: '',
        role: '',
    }
    const handleRefresh = () => {
        setSelectedUser(null);
        onHandleRefresh()
    }

    const handlePost = async (user : UserType, isEnablement: Boolean) => {
        try {
            const currentUserToken = session.token;
            const newUser = await axios.post(LOCAL_API_BASE+`/user/${user.uid}/${isEnablement ? 'enable' : 'disable'}`,{
                _method: 'post',
            },{
                headers: { 
                    Authorization: `Bearer ${currentUserToken}`,
                    "Content-Type": "multipart/form-data" 
                },                
            }).then(response => {
                const newUser = response.data;
                setResponseMessage({responseType: 'success', message: 'Se ha actualizado correctamente el usuario.'});
                logger.log({ action: 'UPDATE', message: `Usuario actualizado: ${newUser.email}` });
                handleRefresh();
            });
        }
        catch (err: any | AxiosError) {
            if (axios.isAxiosError(err))  {
                setResponseMessage({responseType: 'error', message: err.message});
              } else {
                // Just a stock error
              }
        }
    }

    return (
        <>
            { responseMessage && 
                <Card className="mb-4 px-2 pt-3">
                    <Row>
                        <Col>
                            <p className={responseMessage.responseType === 'success' ? 'text-success' : 'text-danger'}>{responseMessage.message}</p>
                        </Col>
                    </Row>
                </Card>
            }
            <Card>
                <Card.Header>
                    <Stack direction="horizontal" gap={2}>
                        <Col>
                            <h4>Usuarios</h4>
                        </Col>
                        { !selectedUser &&
                            <Col className="ms-auto text-end">
                                <Button onClick={() => setSelectedUser(defaultUser) } variant="dark"><i className="bi bi-plus"></i> Nuevo usuario</Button>
                            </Col>
                        }
                    </Stack>
                </Card.Header>
                <Card.Body>
                    { !selectedUser && (
                    <Table hover variant="light" size="lg" responsive>
                        <thead>
                            <tr>
                                <th><i className="bi bi-person"></i> Nombre</th>
                                <th><i className="bi bi-person"></i> Email</th>
                                <th><i className="bi bi-activity"></i> Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                        { data.map((user, index) => {
                                return (
                                <tr key={index} className={user.status === '0' ? 'text-muted' : ''}>
                                    <td><Image src={`https://ui-avatars.com/api/?size=48&name=${user.name}`} className="me-2" roundedCircle /> { user.name } {user.role=='admin' ? <Badge bg="primary" pill>Admin</Badge> : ''}</td>
                                    <td>{ user.email }</td>
                                    <td>
                                        { user.status === '1' &&
                                            <>
                                            <Button onClick={() => setSelectedUser(user) } variant="outline-primary" ><i className="bi bi-pencil"></i>Editar </Button>
                                            <Button onClick={() => handlePost(user, false)} variant="transparent" className="btn-sm"><i className="bi bi-trash"></i>Eliminar</Button>
                                            </>
                                        }
                                        { user.status === '0' && currentUser?.role=='admin' &&
                                            <Button onClick={() => handlePost(user, true)} variant="transparent" className="btn-sm"><i className="bi bi-trash"></i>Reactivar</Button>
                                        }
                                    </td>
                                </tr>);
                            })}
                        </tbody>
                    </Table>
                    )}
                    {selectedUser && 
                        <UsersForm 
                            selectedUser={selectedUser} 
                            onHandleCancel={() => setSelectedUser(null)}
                            onHandleClose={handleRefresh} />
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default UsersTable