import React from 'react';
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
//import { saveAs } from 'file-saver';

const ExportButton = ({ data, label, customFileName } : any) => {
  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: fileType });
    const fileName = customFileName + fileExtension;
    //saveAs(blob, fileName);
  };

  return (
    <Button className='btn-sm' variant="success" onClick={exportToExcel}>{label}</Button>
  );
};

export default ExportButton;