import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../../context/sessionContext";
import { ProjectContext } from '../../context/projectContext';
import './LockScreen.scss';
import useLogger from "../../hooks/useLogger";
import axios from "axios";
import { LOCAL_API_BASE } from "../../data/project"; 

type ErrorMessage = {
    name: String,
    message: String,
}

const LookScreen = () => {
    const navigate = useNavigate();
    const [session, setSession] = useSessionContext();
    const context = useContext(ProjectContext);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessages, setErrorMessages] = useState<ErrorMessage | null>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const logger = useLogger()

    
    if(window.location.href.indexOf('?logout') > -1)
        handleLogout()

    const errors = {
        email: "Email o contraseña inválida",
        pass: "Email o contraseña inválida"
    };

    const renderErrorMessage = (name : String) =>
        name === errorMessages?.name && (
            <div className="error mt-2">{errorMessages.message}</div>
        );
    useEffect(() => {
        if(session.user)
            logger.log({
                action: 'LOGIN',
                message: 'Inicio de sesión',
            })
    },[session])

    const manualLogInWithEmailAndPassword = async (email : string,password : string) => {
        const auth = await axios.post(LOCAL_API_BASE+'/login',{
            email,
            password
        },{
            headers: { "Content-Type": "multipart/form-data" },
        });
        return auth.data
    }

    const handleSubmit = async () => {

        //const userData = context?.project.auth.find((user:any) => user.email===email.value);
        let userData;
        try {
            userData = await manualLogInWithEmailAndPassword(email,password).then( async response => {
                let remoteUser = response.user
                // Valid login
                setSession({
                    ...session, 
                    user : {
                        uid: remoteUser.id,
                        email: remoteUser.email,
                        displayName: remoteUser.name,
                        role: remoteUser.role || 'user',
                    },
                    isAuthenticated: true,
                    token: response.token,
                });
            });
        }
        catch (error) {
            console.log("error:",error);
            setErrorMessages({ name: "email", message: errors.email });
        }
    }

    useEffect(() => {
        if(session.isAuthenticated){
            //console.warn("useEffect-session.isAuthenticated:",session.isAuthenticated)
            //console.warn("useEffect-session.redirectPath:",session.redirectPath)
            navigate(session.redirectPath);
        }
    },[session])

    async function handleLogout(){
        console.warn("LockScreen - handleLogout");
        const currentUserToken = session.token;
        const auth = await axios.post(LOCAL_API_BASE+'/logout',{
            headers: { 
                Authorization: `Bearer ${currentUserToken}`,
                "Content-Type": "multipart/form-data" 
            }, 
        });
        console.warn("auth",auth);
        //return auth.data
    }

    return (
        <>
            <Container fluid className="login-page flex align-items-center justify-content-center">
                <Row className="">
                    <Col md='6' className="text-center">
                        <Card style={{ width: '18rem' }}>
                            <Card.Header>
                                <div className="login-page--logo" style={{backgroundImage: `url('${ context?.project.styles?.intro?.profile }')`}}></div>
                            </Card.Header>
                            <Card.Body>
                                    <Form.Group className="" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="pass">
                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control type="password" placeholder="Contraseña" name="pass" onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>
                                    <Button variant="primary" className="text-white" onClick={handleSubmit}>
                                        Acceder
                                    </Button>
                                    {renderErrorMessage("email")}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default LookScreen;