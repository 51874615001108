import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ExternalContentModal.scss';

type Props = {
    variant: string,
    show: boolean | undefined,
    className: string
    onHide: () => void
}

const ExternalContentModal = ({variant, show, className, onHide} : Props) => {
  return (
    <Modal
      className={className}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Button className="btn-close btn-close-red" onClick={() => onHide()}><span>X</span></Button>
      </Modal.Header>
      <Modal.Body>
        { variant === 'tour' &&
            <div className="tour-wp">
                <iframe src="https://popinvestments.com/portiko/360/"></iframe>
            </div>

        }
        { variant === 'video' &&
            <div className="video-wp">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/kXdSho4vhlI" title="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </div>
        }
      </Modal.Body>
    </Modal>
  );
}

export default ExternalContentModal;