import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap"
import { useSessionContext } from "../../../context/sessionContext"
import { Link } from "react-router-dom"

const PanelHeader = () => {
    const [session] = useSessionContext()

    return(
        <Navbar expand="lg" fixed="top" className="bg-body-tertiary justify-content-between" bg="primary" data-bs-theme="dark">
            <Container>
                <Link to='/home' style={{textDecoration: 'none'}}><Navbar.Brand className="text-white fw-semibold">Portiko</Navbar.Brand></Link>
            </Container>
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link className="text-white"><Link to='/panel/home' className="text-white">{ session.user?.displayName }</Link> &rsaquo;</Nav.Link>
                        <Nav.Link href={window.location.origin+window.location.pathname} className="text-white">Salir <i className="bi bi-power"></i></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default PanelHeader