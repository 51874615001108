import { Badge, Card, Col, Image, Row, Table } from "react-bootstrap"
import moment from 'moment'
import 'moment/locale/es'
import ExportButton from "../ExportButton/ExportButton";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
moment.locale('es');


type Props = {
    data: any[],
}

const LogTable = ({data} : Props) => {

    const [parsedData, setParsedData] = useState<any>()
    const [datatableData, setDatatableData] = useState<any>()
    const getActionType = (action : string) => {
        let actionType = 'info';
        switch(action) {
            case 'REGISTER': actionType = 'dark'; break;
            case 'VIEW': actionType = 'success'; break;
            default: break;
        }
        return actionType;
    }

    const customStyles = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                color: '#202124',
                fontSize: '14px',
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
                outline: '1px solid #FFFFFF',
            },
        },
        pagination: {
            style: {
                border: 'none',
            },
        },
    };

    useEffect(() => {
        const parsed = data.map((log, index) => {
            return {
                'date': moment(log.created_at).format('YYYY-MM-DD HH:mm:ss'),
                'user': log.user.name,
                'action': log.action,
                'message': log.message,
                'location': log.location || 'No disponible'
            }
        })
        setParsedData(parsed);
        const columns = [
            {   name: 'Fecha',
                selector: (row: any) => row.date,
                cell: (row: any) => moment(row.date).calendar(null, { sameElse: 'DD MMM, YYYY - HH:mm' }),
                sortable: true,
                maxWidth: '200px',
            },
            {   name: 'Usuarios',
                selector: (row: any) => row.user,
                cell: (row: any) => <><Image src={`https://ui-avatars.com/api/?size=48&name=${row.user}`} className="me-2" roundedCircle /> {row.user}</>,
                sortable: true,
                maxWidth: '200px',
            },
            {   name: 'Operacion',
                selector: (row: any) => row.action,
                cell: (row: any) => <Badge bg={getActionType(row.action)}>{ row.action }</Badge>,
                sortable: true,
                maxWidth: '100px',
            },
            {   name: 'Acciones',
                selector: (row: any) => row.message,
            },
            {   name: 'Ubicación',
                selector: (row: any) => row.location,
                sortable: true,
            },
        ];
        setDatatableData({
            columns,
            data: parsed
        })
    },[data])

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        Últimos movimientos 
                    </Col>
                    <Col className="mr-0 text-end">
                        <ExportButton label="Exportar" data={parsedData} customFileName='Portiko - ultimos movimientos'  />
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                { datatableData &&
                    <DataTable
                        columns={datatableData.columns}
                        data={datatableData.data}
                        customStyles={customStyles}
                        highlightOnHover
                        pagination
                    />
                }
            </Card.Body>
        </Card>
    )
}

export default LogTable